<template>
  <swiper
    class="slider-swiper"
    :class="{
      '--calculated-padding': paddingLeft - rowLimit > 0,
      '--no-padding-bottom': props.slides?.length <= 1,
    }"
    :style="{
      '--padding-left': `${paddingLeft}px`,
    }"
    :navigation="{
      nextEl: nextButton,
      prevEl: prevButton,
    }"
    :modules="[Pagination, A11y, Navigation]"
    :slides-per-view="props.options?.slidesPerView || 'auto'"
    :pagination="{ clickable: false }"
    :lazy="true"
    @swiper="onSwiper"
  >
    <swiper-slide v-for="(slide, index) in props.slides" :key="index">
      <div class="slider-swiper__slide__wrap">
        <WidgetContentBlock :content="slide" />
      </div>
    </swiper-slide>

    <!-- Navigation-->
    <div class="swiper-buttons m-top-5" v-if="props.slides?.length > 1">
      <button
        class="swiper-button-prev"
        ref="prevButton"
        aria-label="Button Prev"
      ></button>
      <button
        class="swiper-button-next"
        ref="nextButton"
        aria-label="Button Next"
      ></button>
    </div>
  </swiper>
</template>

<script>
export default {
  name: 'WidgetSliderSwiper',
}
</script>

<script setup>
import { debounce } from '~/assets/js/utils.js'

import { Pagination, A11y, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'

const props = defineProps({
  slides: {
    type: Array,
    default: () => [],
  },
  options: {
    type: Object,
  },
})

const slider = ref(null)

const paddingLeft = ref(0)
const rowLimit = 150
const paddingLimit = 1600

const nextButton = ref(null)
const prevButton = ref(null)

onMounted(() => {
  window.addEventListener('resize', debounce(onResize))
  onResize()
})

onUnmounted(() => {
  window.removeEventListener('resize', debounce(onResize), false)
})

const onResize = () => {
  paddingLeft.value = Math.round((window.innerWidth - paddingLimit) / 2)
}

const onSwiper = (swiper) => {
  slider.value = swiper
}
</script>

<style lang="scss">
@import './style.scss';
</style>
